






















































































.box-grafico {
  position: relative;

  > span {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}
